import React, { useState, Fragment } from "react";
import axios from 'axios';

const personalMembership = (props) => {

    const resetPersonalInput = () => {
        document.getElementById('personalName').value = ""; 
        document.getElementById('personalPhone').value = "";
    };

    const personalRetrieve = () => {
        let 
          personalName = document.getElementById('personalName').value
        , personalPhone = document.getElementById('personalPhone').value
        ;

        if(personalName == '') alert('이름을 입력해주세요');
        else if(personalPhone == '') alert('전화번호를 입력해주세요');
        
        if(personalName != '' && personalPhone != '') {
            let options = { 
                id: '63a50ea1be755ed0cad9e6ef',
                params: {
                    name: personalName,
                    phone_number: personalPhone
                }
            }
            axios.post(
              'https://primesheet.net/query/call',
              JSON.stringify(options),
              {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
            })
            .then(function (response) { 
                if(response.data.result == null)  alert('검색결과가 없습니다.');
                else { 
                    resetPersonalInput(); 
                    window.open('../membership/membership.html?client_key='+response.data.result.id)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
                                    
    };

    return (
        <Fragment>
            <div
                className={
                    "wrapper__modal-wrap modal fade " + (props.modalPersonal ? "show" : "")
                }
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div
                    className="bg__wrap"
                    onClick={() => {props.onCLickModalPersonal(); resetPersonalInput();}}
                ></div>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content position-relative">
                        <div className="close__wrap pointer" onClick={() => {props.onCLickModalPersonal(); resetPersonalInput();}}>
                            <img src="./../images/icon (1).png" alt="" />
                        </div>

                        <h4 className="semi-bold font__size--22 text__22-1024 mb-4">Personal Membership</h4>

                        <div className="mb-3">
                            <h2 className="semi-bold font__size--32 text__32-1024 mb-0">개인 멤버십 조회</h2>
                            <p className="font__size--16 text__16-1024 normal color__gray-2 wb-ws">이름과 전화번호 입력으로 멤버십 사용 내역과 잔액을 확인하실 수 있습니다{/*<span className="semi-bold font__size--14 text__14-1024">$69</span>*/}</p>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="medium font__size--20 text__20-1024">이름</label>
                                    <div className="wrapper__field-wrap d-flex align-items-center text-right">
                                        {/*<span className="medium font__size--24 text__24-1024 wrap__logo">$</span>*/}
                                        <input type="text" className="form-control text-left medium font__size--16 text__16-1024" placeholder="이름을 입력하세요" id="personalName" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="medium font__size--20 text__20-1024">전화번호</label>
                                    <div className="wrapper__field-wrap d-flex align-items-center text-right">
                                        {/*<span className="medium font__size--24 text__24-1024 wrap__logo">$</span>*/}
                                        <input type="text" className="form-control text-left medium font__size--16 text__16-1024" placeholder="'-'없이 숫자만 입력하세요" maxLength="11" id="personalPhone" onKeyUp={(e) => {
                                            if(e.keyCode == 13) personalRetrieve();
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="wrapper__action-filter d-flex flex-wrap align-items-center justify-content-center">
                            <a href="#!" className="medium font__size--16 text__16-1024 btn btn__blue color__white shadow rounded__50 mr__1" onClick={() => {personalRetrieve()}}>조회하기</a>
                            <a href="#!" className="medium font__size--16 text__16-1024 btn btn__red color__white shadow rounded__50" onClick={() => resetPersonalInput()}>초기화</a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default personalMembership;
