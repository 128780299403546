import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import axios from 'axios';

const Contact = (props) => {

    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar />

                <section>
                    <div className="container">
                        <div className="wrapper__head-top position-relative">
                            <img src="./../images/Group 82.png" className="images__1 d-none d-md-block" alt="" />
                            <img src="./../images/Frame 889.png" className="images__2 d-none d-md-block" alt="" />
                            <div className="text-center position-relative z-2">
                                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 justify-content-center ls-1">Inconveniences</h5>
                                <h1 className="semi-bdol font__size--48 text__48-1024 text__48-md text__48-sm ls-1">불편사항 접수</h1>
                                <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1 wb-ws">사용하시면서 불편하셨던 점들을 전달하고 싶으시다면 적어주세요.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="" className="medium font__size--18 text__14-1024 color__black">불편사항</label>
                                    <textarea className="font__size--16 text__14-1024 color__gray-2 form-control wrapper__input-field textarea ls-1" placeholder="불편하셨던 점들을 적어주세요. 철저하게 익명이 보장됩니다." name="" id="incvn" cols="30" rows="10"></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="medium font__size--18 text__14-1024 color__black">이용하셨던 룸</label>
                                    <input type="text" className="font__size--16 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="어떤룸이 불편하셨는지 적어주시면 개선에 참고하도록 하겠습니다" id="usedRoom" />
                                </div>
                                <div className="form-group mb-5">
                                    <label htmlFor="" className="medium font__size--18 text__14-1024 color__black">전화번호</label>
                                    <input type="text" className="font__size--16 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="답변을 전달받고 싶으시다면 '-'없이 숫자만 입력해주세요 (필수아님)" id="incvnPhone" maxlength="11" />
                                </div>
                                <div className="text-right">
                                    <button className="medium font__size--16 text__16-1024 btn btn__blue shadow rounded__50 color__white ls-1" onClick={() => {
                                        let 
                                          incvn      = document.getElementById('incvn').value
                                        , usedRoom   = document.getElementById('usedRoom').value
                                        , incvnPhone = document.getElementById('incvnPhone').value
                                        ; 
                                        
                                        if(incvn == '') { alert('불편하셨던 점을 입력해주세요');}
                                        if((incvn != '')) {
                                          let options = { 
                                              id: '63293e39cfebbc291545e385',
                                              params: {
                                                id: null,
                                                name: "불편사항",
                                                phone_number: incvnPhone != null? incvnPhone : null,
                                                book_way: '선택안함',
                                                book_path: '홈페이지',
                                                book_type: usedRoom != null? usedRoom : null,
                                                book_date: Date.now(),
                                                start_time: '00:00',
                                                requested_term: incvn,
                                                memo: null,
                                                approval: 0,
                                                elapsed_hours: '00:00',
                                                book_product: null,
                                                using_people: null,
                                                appointed_pay: null,
                                                final_price: null,
                                                register_date: Date.now() 
                                              }
                                            }

                                          axios.post(
                                            'https://primesheet.net/query/call',
                                            JSON.stringify(options),
                                            {
                                            headers: {
                                              "Content-Type": "application/json; charset=utf-8",
                                            },
                                          })
                                          .then(function (response) { 
                                            document.getElementById('incvn').value = "";
                                            document.getElementById('usedRoom').value = "";
                                            document.getElementById('incvnPhone').value = "";
                                            alert("불편사항 접수가 완료되었습니다.\n적어주신 의견 소중히 받아 이용 개선에 참고하도록 하겠습니다.\n감사합니다:)"); 
                                          })
                                          .catch(function (error) {
                                            console.log(error);
                                          });
                                        }
                                    }}>접수하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    );
};

export default Contact;
