import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import CardTrip from "../../component/Card/CardTrip";
import Filter from "../../component/Modal/FIlter";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import Select from 'react-select';
import axios from 'axios';

const Index = (props) => {
  const video = useRef(null);

  const [play, setPlay] = useState(false);

  const tootlePlay = () => {
    if (!play) {
      video.current.play();
    } else {
      video.current.pause();
    }

    setPlay(!play);
  };

  let selectOptions = {
    meeting: [],
    healing: [],
    cafe: [],
    start: [],
    hour: [],
    bigRoomHour: []
  }

  for (var i=0;i<12;i++) {
    selectOptions.meeting.push({value:(i+1), label:(i+1)+"명"});
    selectOptions.healing.push({value:(4+i), label:(4+i)+"명"});
    selectOptions.hour.push({value:(1+i)+":00", label:(1+i)+"시간"});
    if (i < 11) selectOptions.hour.push({value:(1+i)+":30", label:(1+i)+"시간 30분"});
    selectOptions.bigRoomHour.push({value:(2+i)+":00", label:(2+i)+"시간"});
    if (i < 11) selectOptions.bigRoomHour.push({value:(2+i)+":30", label:(2+i)+"시간 30분"});
  }

  for (var i=0;i<15;i++) {
    selectOptions.cafe.push({value:(6+i), label:(6+i)+"명"});
  }

  for(var i=0;i<24;i++) { // 24시까지 표현하기 위해 25시였는데 오전/오후 label로 바뀌면서 필요 없어짐
    selectOptions.start.push({value:i+":00", label:(i<12? "오전 ":"오후 ")+(i<13? i:(i-12))+"시"});
    if (i < 24) selectOptions.start.push({value:i+":30", label:(i<12? "오전 ":"오후 ")+(i<13? i:(i-12))+"시 30분"});
  }  

  let destTypeOne = [];
  let destTypeTwo = [];
  let destTypeThree = [];
  let destTypeFour = [];

  const destinations = [
        {
            id: "healing",
            img: "./../images/healing/h2.jpg",
            title: "힐링/파티룸",
            location: "최대 15-20인",
            rating: "4인기준",
            date: "평일",
            price: "15,000",
        },
        {
            id: "cafe",
            img: "./../images/cafe/c1.jpg",
            title: "카페룸",
            location: "최대 20인",
            rating: "6인기준",
            date: "평일/주말",
            price: "15,000",
        },
        {
            id: "r1",
            img: "./../images/r1/m1.jpg",
            title: "8인룸 R1",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r2",
            img: "./../images/r2/m1.jpg",
            title: "8인룸 R2",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },
        /*{
            id: "r8",
            img: "./../images/r8/m2.jpg",
            title: "8인룸 R8",
            location: "최대 8인",
            rating: "3인기준",
            date: "평일",
            price: "1,500",
        },*/
        {
            id: "r3",
            img: "./../images/r3/m3.jpg",
            title: "4인룸 R3",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r4",
            img: "./../images/r4/m3.jpg",
            title: "4인룸 R4",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r7",
            img: "./../images/r7/m2.jpg",
            title: "4인룸 R7",
            location: "최대 4인",
            rating: "2인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "r5",
            img: "./../images/r5/m2.jpg",
            title: "10인룸 VIP(R5)",
            location: "최대 10인",
            rating: "4인기준",
            date: "평일",
            price: "12,000",
        },
        {
            id: "r6",
            img: "./../images/r6/r6.jpg",
            title: "12인룸 R6",
            location: "최대 12인",
            rating: "6인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "mYellow",
            img: "./../images/mini/m2.jpg",
            title: "미니룸(옐로우)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "mOrange",
            img: "./../images/mini/m5.jpg",
            title: "미니룸(오렌지)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        },
        {
            id: "mBlue",
            img: "./../images/mini/m8.jpg",
            title: "미니룸(블루)",
            location: "최대 2인",
            rating: "1인기준",
            date: "평일",
            price: "1,500",
        }
    ]

  destinations.map((obj, idx) => {
    if((obj.location.indexOf('4인') > -1 || obj.title.indexOf('미니') > -1)) {
      if (destTypeOne.length < 4) destTypeOne.push(obj);
    } else if(obj.location.indexOf('8인') > -1) {
      destTypeTwo.push(obj);
    } else if((obj.location.indexOf('10인') > -1 || obj.title.indexOf('12인') > -1)) {
      destTypeThree.push(obj);
    } else if(obj.location.indexOf('20인') > -1) {
      destTypeFour.push(obj);
    }
  })


  const [modalFilter, setModalFilter] = useState(false);
  const onCLickModalFilter = () => {
    if (!modalFilter) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalFilter(!modalFilter);
  };

  const [reservationDate, setReservationDate] = useState(new Date().setHours(0,0,0,0,0,0));

  const [timeValue, setTimeValue] = useState('');

  const [Selected, setSelected] = useState("")
  const handleSelect = (e) => {
    setSelected(e.value);
  };

  const [people, setPeople] = useState(3);
  const [healingPeople, setHealingPeople] = useState(4);
  const [cafePeople, setCafePeople] = useState(6);
  const [start, setStart] = useState((new Date().getHours()+1)+":00");
  const [hour, setHour] = useState("2:00");

  const timeConverter = (t, b) => {
      const ts = t.split(":");
      return (b ? "" : (ts[0] < 12 ?"오전 " : "오후 "))+(ts[0]<13? ts[0]:(ts[0]-12))+"시"+(b ? "간" : "")+(ts[0] == "30" ? " 30분" : "");
  }

  return (
    <Fragment>
      <Filter
        modalFilter={modalFilter}
        onCLickModalFilter={() => onCLickModalFilter()} />
      <div className="overflow-hidden">
        <Navbar />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 ls-1">공간잇에서는 모두 가능합니다</h5>
                <h1 className="semi-bdol font__size--64 text__65-1024 wb-keep text__65-sm text__65-xxs ls-1">규모별 미팅, 강의, 워크샵, 모임과 파티, 콘서트와 영화감상까지</h1>
                <p className="normal font__size--16 text__16-1024 color__gray-2 mb-3 mb-sm-5 ls-1 wb-ws">전용면적 100평 이상의 공간대여 전문점으로 11개의 세분화된 공간구성 2인~20인룸과 전체대관이 가능해 다양한 모임 형태에 맞춰서 예약 및 이용이 가능합니다.</p>

                <div className="wrapper__tab-filter-travel my-4 mb-sm-5">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link medium color__gray-3 font__size--14 text__14-1024 active" id="meeting-rooms-tab" data-toggle="pill" href="#meeting-rooms" role="tab" aria-controls="meeting-rooms" aria-selected="true">미팅룸(1~12인)</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link medium color__gray-3 font__size--14 text__14-1024" id="cafe-rooms-tab" data-toggle="pill" href="#cafe-room" role="tab" aria-controls="cafe-room" aria-selected="true">카페룸(6~20인)</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link medium color__gray-3 font__size--14 text__14-1024" id="healing-room-tab" data-toggle="pill" href="#healing-room" role="tab" aria-controls="healing-room" aria-selected="false">힐링/파티룸(4~15인)</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link medium color__gray-3 font__size--14 text__14-1024" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">전체대관</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="meeting-rooms" role="tabpanel" aria-labelledby="meeting-rooms-tab">
                      <div className="wrapper__tab-grid">
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용인원</h5>
                              <Select options={selectOptions.meeting} defaultValue={selectOptions.meeting[2]} id="meetingPeople" onChange={(e) => {setPeople(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items pb__075 mxw__50">
                          <img src="./../images/calendar-add.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024 mb__category">이용날짜</h5>
                            <DatePicker
                              locale={ko} 
                              dateFormat="yyyy-MM-dd"
                              selected={reservationDate}
                              onChange={(date: Date) => {if(typeof(date) != 'number' ) {date = new Date(date).getTime();} setReservationDate(date);}}
                              startDate={reservationDate}
                              id="meetingDate"
                            />
                          </div>
                        </div>
                        <div className="line sm-none"></div>
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/radar.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">시작시각</h5>
                            <Select options={selectOptions.start} defaultValue={{label:timeConverter(start), value:start}} id="meetingStart"  onChange={(e) => {setStart(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items pr__1 mxw__50">
                          <img src="./../images/setting-4.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용시간</h5>
                            <Select options={selectOptions.hour} defaultValue={{label:timeConverter(hour, 1), value:hour}} id="meetingHour"  onChange={(e) => {setHour(e.value)}} />
                          </div>
                        </div>
                        <div className="line none"></div>
                        <button className="btn btn__blue color__white shadow rounded__50 btn-width" onClick={() => {
                            let chkRsvDate = new Date(reservationDate).setHours(start.split(':')[0],start.split(':')[1],0,0,0,0);
                            if(chkRsvDate < new Date().getTime()) return alert("이전 날짜는 예약할 수 없습니다.");
                            else window.open(`./reservation/reservation.html?room=미팅룸&people=${people}&date=${reservationDate}&start=${start}&hour=${hour}`)
                          } }>
                          <div className="d-flex align-items-center">
                            <img src="./../images/search-normal.png" alt="" />
                            <span className="semi-bold nowrap font__size--16 text__16-1024 ml-2">빠른예약</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="cafe-room" role="tabpanel" aria-labelledby="healing-room-tab">
                      <div className="wrapper__tab-grid">
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용인원</h5>
                            <Select options={selectOptions.cafe} defaultValue={selectOptions.cafe[0]} id="cafePeople" onChange={(e) => {setCafePeople(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items mxw__50 pb__075">
                          <img src="./../images/calendar-add.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024 mb__category">이용날짜</h5>
                            <DatePicker
                              locale={ko} 
                              dateFormat="yyyy-MM-dd"
                              selected={reservationDate}
                              onChange={(date: Date) => { if(typeof(date) != 'number' ) {date = new Date(date).getTime();} setReservationDate(date); }}
                              startDate={reservationDate}
                              id="cafeDate"
                            />
                          </div>
                        </div>
                        <div className="line sm-none"></div>
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/radar.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">시작시각</h5>
                            <Select options={selectOptions.start} defaultValue={{label:timeConverter(start), value:start}} id="cafeStart"  onChange={(e) => {setStart(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items mxw__50 pr__1">
                          <img src="./../images/setting-4.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용시간</h5>
                            <Select options={selectOptions.bigRoomHour} defaultValue={{label:timeConverter(hour, 1), value:hour}} id="cafeHour"  onChange={(e) => {setHour(e.value)}} />
                          </div>
                        </div>
                        <div className="line none"></div>
                        <button className="btn btn__blue color__white shadow rounded__50" onClick={() => {
                          let chkRsvDate = new Date(reservationDate).setHours(start.split(':')[0],start.split(':')[1],0,0,0,0);
                          if(chkRsvDate < new Date().getTime()) return alert("이전 날짜는 예약할 수 없습니다.");
                          window.open(`./reservation/reservation.html?room=카페룸&people=${cafePeople}&date=${reservationDate}&start=${start}&hour=${hour}`)} }>
                          <div className="d-flex align-items-center">
                            <img src="./../images/search-normal.png" alt="" />
                            <span className="semi-bold nowrap font__size--16 text__16-1024 ml-2">빠른예약</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="healing-room" role="tabpanel" aria-labelledby="healing-room-tab">
                      <div className="wrapper__tab-grid">
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용인원</h5>
                            <Select options={selectOptions.healing} defaultValue={selectOptions.healing[0]} id="healingPeople" onChange={(e) => {setHealingPeople(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items mxw__50 pb__075">
                          <img src="./../images/calendar-add.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024 mb__category">이용날짜</h5>
                            <DatePicker
                              locale={ko} 
                              dateFormat="yyyy-MM-dd"
                              selected={reservationDate}
                              onChange={(date: Date) => { if(typeof(date) != 'number' ) {date = new Date(date).getTime();} setReservationDate(date); }}
                              startDate={reservationDate}
                              id="healingDate"
                            />
                          </div>
                        </div>
                        <div className="line sm-none"></div>
                        <div className="d-flex align-items-start items mxw__50">
                          <img src="./../images/radar.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">시작시각</h5>
                            <Select options={selectOptions.start} defaultValue={{label:timeConverter(start), value:start}} id="healingStart"  onChange={(e) => {setStart(e.value)}} />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items mxw__50 pr__1">
                          <img src="./../images/setting-4.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용시간</h5>
                            <Select options={selectOptions.bigRoomHour} defaultValue={{label:timeConverter(hour, 1), value:hour}} id="healingHour"  onChange={(e) => {setHour(e.value)}} />
                          </div>
                        </div>
                        <div className="line none"></div>
                        <button className="btn btn__blue color__white shadow rounded__50" onClick={() => {
                          let chkRsvDate = new Date(reservationDate).setHours(start.split(':')[0],start.split(':')[1],0,0,0,0);
                          if(chkRsvDate < new Date().getTime()) return alert("이전 날짜는 예약할 수 없습니다.");
                          window.open(`./reservation/reservation.html?room=힐링룸&people=${healingPeople}&date=${reservationDate}&start=${start}&hour=${hour}`)} }>
                          <div className="d-flex align-items-center">
                            <img src="./../images/search-normal.png" alt="" />
                            <span className="semi-bold nowrap font__size--16 text__16-1024 ml-2">빠른예약</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div className="wrapper__tab-grid">
                        <div className="d-flex align-items-start items">
                          <img src="./../images/calendar-add.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이용날짜</h5>
                            <DatePicker
                              locale={ko} 
                              dateFormat="yyyy-MM-dd"
                              selected={reservationDate}
                              onChange={(date: Date) => { if(typeof(date) != 'number' ) {date = new Date(date).getTime();} setReservationDate(date); }}
                              startDate={reservationDate}
                              id="allDate"
                            />
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="d-flex align-items-start items">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">예약문의</h5>
                            <input type="text" id="allInquiry" placeholder="해당날짜 대관 가능한가요?" className="normal font__size--14 text__14-1024" />
                          </div>
                        </div>
                        <div className="d-flex align-items-start items">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">이름</h5>
                            <input type="text" id="allName" placeholder="이름을 반드시 입력해주세요." className="normal font__size--14 text__14-1024" />
                          </div>
                        </div>
                        <div className="d-flex align-items-start items">
                          <img src="./../images/profile-2user.png" className="icon" alt="" />
                          <div className="ml-2">
                            <h5 className="medium font__size--16 text__16-1024">전화번호</h5>
                            <input type="text" id="allPhone" placeholder="'-'없이 숫자만 입력하세요." className="normal font__size--14 text__14-1024" maxLength="11" />
                          </div>
                        </div>
                        <div className="line sm-none"></div>
                        <div className="line none"></div>
                        <button className="btn btn__blue color__white shadow rounded__50" onClick={() => { 
                              let 
                                date = new Date(document.getElementById('allDate').value).getTime()
                              , inquiry = document.getElementById('allInquiry').value
                              , name = document.getElementById('allName').value
                              , phone = document.getElementById('allPhone').value
                              ; 

                              if(date == '') { alert('이용날짜를 선택해주세요');}
                              else if(name == '') { alert('이름을 입력해주세요');}
                              else if(phone == '') { alert('연락받으실 전화번호를 입력해주세요');}
                              else if(date < new Date().setHours(0,0,0,0,0,0)) { return alert('이전 날짜는 예약할 수 없습니다.')}
                              if((date != '') && (name != '') && (phone != '')) {
                                let options = { 
                                    id: '63293e39cfebbc291545e385',
                                    params: {
                                      id: null,
                                      name: name,
                                      phone_number: phone,
                                      book_way: '선택안함',
                                      book_path: '빠른예약',
                                      book_type: '전체대관',
                                      book_date: date,
                                      start_time: '00:00',
                                      requested_term: inquiry,
                                      memo: null,
                                      approval: 0,
                                      elapsed_hours: '24:00',
                                      book_product: '63350681cfebbc291545e69b',
                                      using_people: null,
                                      appointed_pay: null,
                                      final_price: null,
                                      register_date: Date.now() 
                                    }
                                }

                                axios.post(
                                  'https://primesheet.net/query/call',
                                  JSON.stringify(options),
                                  {
                                  headers: {
                                    "Content-Type": "application/json; charset=utf-8",
                                  },
                                })
                                .then(function (response) { 
                                  setReservationDate(Date.now());
                                  document.getElementById('allInquiry').value = "";
                                  document.getElementById('allName').value = "";
                                  document.getElementById('allPhone').value = "";
                                  alert("대관 문의가 완료되었습니다. 일정 확인 후 연락드리도록 하겠습니다. 일정변경이나 다른 문의는 네이버톡톡 또는 (1599-3987)로 문의주세요:)"); 
                                })
                                .catch(function (error) {
                                  console.log(error);
                                });
                              }
                            }
                          }>
                          <div className="d-flex align-items-center">
                            <img src="./../images/search-normal.png" alt="" />
                            <span className="semi-bold nowrap font__size--16 text__16-1024 ml-2">예약문의</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper__people d-flex align-items-center">
                  <span className="ml-3 normal font__size--14 text__12-1024 ls-1 wb-ws">예약은 <span className="medium">전화(1599-3687), 네이버톡톡, 홈페이지 실시간예약</span> 모두 편리하게 가능합니다!</span>
                  <svg className="Header__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1337.97 684.43">
                      <path className="Header__shape bigSquare" fill="#16d5d1" d="M546.519 349.271l86.383-56.098 56.097 86.383-86.383 56.098z" />
                      <path className="Header__shape triangle" fill="none" stroke="#ff4676" strokeWidth="8" d="M372.15 462.17L321 434.58l-4.88 56.16z" />
                      <circle className="Header__shape bigCircle" fill="#ff4676" cx="1076.52" cy="262.17" r="59" />
                      <path className="Header__shape littleSquare" fill="#ffe430" d="M285.523 262.61l12.372-53.59 53.59 12.372-12.372 53.59z" />
                      <circle className="Header__shape hoop" fill="none" stroke="#ffe430" strokeWidth="13" cx="905.52" cy="447.17" r="45" />
                      <circle className="Header__shape littleCircle" fill="#0f1c70" cx="1036.52" cy="203.17" r="27" />
                    </svg>
                </div>
              </div>
              <div className="col-lg-6 d-none d-lg-block my-auto">
                <img src="./../images/gr1.png" className="images__head-1" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm ls-1 wb-ws">공간잇이 만들어가는 <br className="d-none d-sm-block wb-ws"/>다양한 유형의 공간들을 소개합니다</h2>
              <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1">공간잇은 11개의 다양한 룸 타입을 제공하고 있습니다</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="row justify-content-center">
                  <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                    <div className="d-flex align-items-center wrapper__card-list-explore">
                      <img src="./../images/sg1.png" className="circle" alt="" />
                      <div className="ml-3">
                        <h5 className="medium font__size--18 text__18-1024 mb-0">미팅룸</h5>
                        <p className="normal font__size--16 text__16-1024 color__gray-2 mb-1 mb-sm-1 ls-1">회의, 모임, 영상, 과외 등</p>
                        <div className="tag medium font__size--14 text__14-1024 color__black">2인 ~ 12인</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                    <div className="d-flex align-items-center wrapper__card-list-explore">
                      <img src="./../images/sg2.png" className="circle" alt="" />
                      <div className="ml-3">
                        <h5 className="medium font__size--18 text__18-1024 mb-0">카페룸</h5>
                        <p className="normal font__size--16 text__16-1024 color__gray-2 mb-1 mb-sm-1 ls-1">강연, 다이닝, 워크샵 등</p>
                        <div className="tag medium font__size--14 text__14-1024 color__black">20인 이내</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
                    <div className="d-flex align-items-center wrapper__card-list-explore">
                      <img src="./../images/sg3.png" className="images__circle" alt="" />
                      <div className="ml-3">
                        <h5 className="medium font__size--18 text__18-1024 mb-0">힐링/파티룸</h5>
                        <p className="normal font__size--16 text__16-1024 color__gray-2 mb-1 mb-sm-1 ls-1">파티, 영화, 콘서트관람, 행사 등</p>
                        <div className="tag medium font__size--14 text__14-1024 color__black">15인 이내</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-0 ls-1">모두가 원하던<br />맞춤형 공간을 소개합니다</h2>
              </div>
              <div className="col-md-6 my-auto">
                <p className="normal font__size--16 text__16-1024 color__gray-2 mb-0 ls-1 wb-ws">공간잇의 11개의 룸은 개인에서부터 단체에 이르기까지 모든 종류의 모임들이 함께할 수 있는 공간을 설계하고 만들어가고 있습니다.</p>
              </div>
            </div>

            <div className="d-flex align-items-start flex-sm-nowrap flex-wrap justify-content-between mb-4">
              <ul className="nav nav-pills wrapper__nav-travel mb-4 mb-lg-0" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link medium font__size--14 text__14-1024 color__black active" id="pills-Popular-tab" data-toggle="pill" data-room="미팅룸" href="#pills-Popular" role="tab" aria-controls="pills-Popular" aria-selected="true">미니/4인룸</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link medium font__size--14 text__14-1024 color__black" id="pills-Islands-tab" data-toggle="pill" href="#pills-Islands" role="tab" aria-controls="pills-Islands" aria-selected="false">8인룸</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link medium font__size--14 text__14-1024 color__black" id="pills-Surfing-tab" data-toggle="pill" href="#pills-Surfing" role="tab" aria-controls="pills-Surfing" aria-selected="false">10~12인룸</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link medium font__size--14 text__14-1024 color__black" id="pills-Nation-tab" data-toggle="pill" href="#pills-Nation" role="tab" aria-controls="pills-Nation" aria-selected="false">카페룸/힐링/파티룸</a>
                </li>
              </ul>
              {/*<div onClick={() => onCLickModalFilter()} className="pointer btn__filter d-flex align-items-center">
                <span className="medium font__size--14 text__14-1024 mr-2">Filters</span>
                <img src="./../images/setting-4.png" alt="" />
              </div>*/}
            </div>
            <div className="tab-content mb-4" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-Popular" role="tabpanel" aria-labelledby="pills-Popular-tab">
                <div className="row">
                  {
                    destTypeOne.map((obj, index) => {
                      return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                        <CardTrip data={obj} />
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="tab-pane fade" id="pills-Islands" role="tabpanel" aria-labelledby="pills-Islands-tab">
                <div className="row">
                  {
                    destTypeTwo.map((obj, index) => {
                      return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                        <CardTrip data={obj} />
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="tab-pane fade" id="pills-Surfing" role="tabpanel" aria-labelledby="pills-Surfing-tab">
                <div className="row">
                  {
                    destTypeThree.map((obj, index) => {
                      return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                        <CardTrip data={obj} />
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="tab-pane fade" id="pills-Nation" role="tabpanel" aria-labelledby="pills-Nation-tab">
                <div className="row">
                  {
                    destTypeFour.map((obj, index) => {
                      return <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                        <CardTrip data={obj} />
                      </div>
                    })
                  }
                </div>
              </div>
            </div>

            <div className="text-center">
              <div className="pointer btn__filter d-inline-block medium font__size--16 text__16-1024" onClick={() => window.open('./Destination', '_self')}>
                전체보기
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-none d-lg-block my-auto">
                <img src="./../images/gr2.png" alt="" />
              </div>
              <div className="col-lg-6 my-auto">
                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 ls-1">신속하고 편리한 이용정책</h5>
                <h1 className="semi-bdol font__size--48 text__48-1024 text__48-md text__48-sm ls-1 wb-ws">다양한 채널로 예약하거나<br /> 현장 방문 후 손쉬운 무인이용</h1>
                <p className="normal font__size--16 text__16-1024 color__gray-2 mb-4 ls-1 wb-ws">전화, 네이버톡톡, 홈페이지 예약을 이용할 수 있으며 예약 요청 시 고객의 모임목적에 맞는 룸 배정과 안내를 도와드리며, 미팅룸 예약/현장이용은 간단한 무인체크인을 통해 손쉽게 이용 가능합니다</p>
                <div className="wrapper__list-archivment d-flex flex-wrap align-items-center">
                  <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img src="./../images/close-circle.png" alt="" />
                      <h5 className="mb-0 semi-bold font__size--24 text__24-1024 ml-2 ls-1">예약 시</h5>
                    </div>
                    <p className="normal color__gray-2 font__size--14 text__14-1024 color__white mb-0 ml-2 lh__4">문자, 전화 등 <br />
                      확정안내</p>
                  </div>

                  <div className="item mb-4 mb-xl-0 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img src="./../images/happyemoji.png" alt="" />
                      <h5 className="mb-0 semi-bold font__size--24 text__24-1024 ml-2 ls-1">문의 시</h5>
                    </div>
                    <p className="normal color__gray-2 font__size--14 text__14-1024 color__white mb-0 ml-2 lh__4">상담 및 톡톡 응답률 <br />
                      100% 달성</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm ls-1">공간잇의 이용혜택</h2>
              <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1">공간잇에선 고객분들의 다양한 이용을 지원합니다</p>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-md-4 mb-4 mb-md-0 text-center">
                    <div className="px-3">
                      <img src="./../images/map.png" className="mb-4" alt="" />
                      <h5 className="medium font__size--24 text__24-1024 ls-1">외부 음식/음료/배달 가능</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1 wb-ws">모임엔 먹고, 마시는 걸 빠뜨릴 수 없죠! 외부 음식/음료 그리고 배달이용이 가능합니다(단체 시 주류반입가능)</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 mb-md-0 text-center">
                    <div className="px-3">
                      <img src="./../images/24-support.png" className="mb-4" alt="" />
                      <h5 className="medium font__size--24 text__24-1024">무료음료 혜택</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1 wb-ws">공간잇에선 커피머신(아메리카노), 각종 티를 무료 제공하며 간단한 스낵과 라면, 음료수를 판매하고 있습니다</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 mb-md-0 text-center">
                    <div className="px-3">
                      <img src="./../images/buildings.png" className="mb-4" alt="" />
                      <h5 className="medium font__size--24 text__24-1024">무료대여품</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1 wb-ws">20여종의 보드게임, 문구류, 충전케이블, 키보드, 마우스 등 꼭 필요했던 비품들을 무료로 대여합니다</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm ls-1 wb-ws">글로 보기 힘드시다구요? <br className="d-none d-md-block" /> 영상으로 쉽게 설명해드릴게요!</h2>
            </div>
            <div className={"wrapper__play-video position-relative " + (play ? "active" : "")}>
              <img src="./../images/bgr.png" className="cover" alt="" />
              <div className="play pointer" onClick={() => tootlePlay()}>
                <img src="./../images/play-circle.png" alt="" />
              </div>
              <video
                ref={video}
                className="video"
                /*loop*/
                controls={true}
              >
                <source
                  src="./../images/ggit.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div className="wrap">
                <div className="d-md-flex text-center text-md-left align-items-center justify-content-between">
                  <h4 className="medium font__size--32 text__32-1024 text__32-md text__32-sm mb-3 mb-md-0 wb-ws">지금 바로 <span className="semi-bold">실시간예약</span> 하실 수 있습니다!</h4>
                  <a href="./reservation/reservation.html" className="btn btn__blue rounded__50 shadow color__white semi-bold font__size--16 text__16-1024" target="_blank">예약 바로가기</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <img src="./../images/gr3.png" alt="" />
              </div>
              <div className="col-md-6 my-auto">
                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 ls-1">맞춤형 멤버십</h5>
                <h1 className="semi-bdol font__size--48 text__48-1024 text__48-md text__48-sm mb-4 ls-1 wb-ws">자주 이용하시는 개인이라면<br />
                  정기적인 단체모임이라면<br />
                  이용시간이 길다면</h1>
                <div className="d-flex align-items-center flex-wrap flex-lg-nowrap">
                  <a href="./Membership" className="medium font__size--16 text__16-1024 btn btn__blue shadow color__white rounded__50">멤버십 소개</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div >
    </Fragment >
  );
};

export default Index;
