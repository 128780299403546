import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import axios from 'axios';

const Contact = (props) => {

    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar />

                <section>
                    <div className="container">
                        <div className="wrapper__head-top position-relative">
                            <img src="./../images/Group 82.png" className="images__1 d-none d-md-block" alt="" />
                            <img src="./../images/Frame 889.png" className="images__2 d-none d-md-block" alt="" />
                            <div className="text-center position-relative z-2">
                                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 justify-content-center ls-1">Membership</h5>
                                <h1 className="semi-bdol font__size--48 text__48-1024 text__48-md text__48-sm ls-1">멤버십안내 및 가입문의</h1>
                                <p className="normal font__size--16 text__16-1024 color__gray-2 ls-1">공간잇 멤버십 회원의 다양한 혜택을 안내합니다</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-0">
                    <div className="container">
                        <div className="row mb__3">
                            <h3 className="semi-bold font__size--32 ls-1 pl__093">멤버십 안내</h3>
                            {/*<p className="normal font__size--16 text__16-1024 color__gray-2 mb-4 ls-1">공간잇 멤버십 가격을 아래와 같이 공지합니다</p>*/}
                        </div>
                        <div className="row mb__3">
                            <div className="col-md-3 mb-4 mb-md-0 membership-table">
                                <div className="head-box">
                                    <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                        <path d="M14.9 11.508A4.822 4.822 0 0 0 15.5 9c0-2.542-1.275-4-3.5-4S8.5 6.458 8.5 9a4.822 4.822 0 0 0 .6 2.508A4.25 4.25 0 0 0 5 15.75V19h14v-3.25a4.25 4.25 0 0 0-4.1-4.242ZM12 6.5c.931 0 2 .284 2 2.5s-1.069 2.5-2 2.5-2-.284-2-2.5 1.069-2.5 2-2.5Zm5.5 11h-11v-1.75A2.753 2.753 0 0 1 9.25 13h5.5a2.753 2.753 0 0 1 2.75 2.75v1.75Z" fill="currentColor"></path>
                                    </svg>
                                    <label className="n__mb__0">Personal</label>
                                </div>
                                <div className="body-box">
                                    <div className="membership-title">5.0 요금제</div>
                                    <div className="membership-desc">55,000/<span className="font__size--16 text__14-1024 color__gray-2">포인트</span></div>
                                    <div className="membership-desc-detail">(+10% 이상의 할인혜택)</div>
                                    <div className="membership-benefits">
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">1개월 내 사용</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">멤버십 회원 전용 이벤트</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">1인 평일요금 기준 <span className="semi-bold">약 40시간</span> 사용 가능</label>
                                        </div>
                                    </div>
                                    <div className="membership-btn" onClick={() => {document.getElementById('membershipName').focus(); }}>
                                        <div className="medium font__size--14 text__14-1024 btn membership btn__blue shadow color__white ls-1">문의하기</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4 mb-md-0 membership-table">
                                <div className="head-box">
                                    <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                        <path d="M14.9 11.508A4.822 4.822 0 0 0 15.5 9c0-2.542-1.275-4-3.5-4S8.5 6.458 8.5 9a4.822 4.822 0 0 0 .6 2.508A4.25 4.25 0 0 0 5 15.75V19h14v-3.25a4.25 4.25 0 0 0-4.1-4.242ZM12 6.5c.931 0 2 .284 2 2.5s-1.069 2.5-2 2.5-2-.284-2-2.5 1.069-2.5 2-2.5Zm5.5 11h-11v-1.75A2.753 2.753 0 0 1 9.25 13h5.5a2.753 2.753 0 0 1 2.75 2.75v1.75Z" fill="currentColor"></path>
                                    </svg>
                                    <label className="n__mb__0">Personal</label>
                                </div>
                                <div className="body-box">
                                    <div className="membership-title">7.0 요금제</div>
                                    <div className="membership-desc">85,000/<span className="font__size--16 text__14-1024 color__gray-2">포인트</span></div>
                                    <div className="membership-desc-detail">(+20% 이상의 혜택)</div>
                                    <div className="membership-benefits">
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">1개월 내 사용</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">멤버십 회원 전용 이벤트</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">1인 평일요금 기준 <span className="semi-bold">약 60시간</span> 사용 가능</label>
                                        </div>
                                    </div>
                                    <div className="membership-btn" onClick={() => {document.getElementById('membershipName').focus(); }}>
                                        <div className="medium font__size--14 text__14-1024 btn membership btn__blue shadow color__white ls-1">문의하기</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4 mb-md-0 membership-table">
                                <div className="head-box">
                                    <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                        <path d="M14.9 11.508A4.822 4.822 0 0 0 15.5 9c0-2.542-1.275-4-3.5-4S8.5 6.458 8.5 9a4.822 4.822 0 0 0 .6 2.508A4.25 4.25 0 0 0 5 15.75V19h14v-3.25a4.25 4.25 0 0 0-4.1-4.242ZM12 6.5c.931 0 2 .284 2 2.5s-1.069 2.5-2 2.5-2-.284-2-2.5 1.069-2.5 2-2.5Zm5.5 11h-11v-1.75A2.753 2.753 0 0 1 9.25 13h5.5a2.753 2.753 0 0 1 2.75 2.75v1.75Z" fill="currentColor"></path>
                                    </svg>
                                    <label className="n__mb__0">Duo</label>
                                </div>
                                <div className="body-box">
                                    <div className="membership-title">10.0 요금제</div>
                                    <div className="membership-desc">130,000/<span className="font__size--16 text__14-1024 color__gray-2">포인트</span></div>
                                    <div className="membership-desc-detail">(+30% 이상의 혜택)</div>
                                    <div className="membership-benefits">
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">2개월 내 사용</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">멤버십 회원 전용 이벤트</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">1인 평일요금 기준 <span className="semi-bold">약 90시간</span> 사용 가능</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">최소 2인이상 멤버십 공유 가능</label>
                                        </div>
                                    </div>
                                    <div className="membership-btn large" onClick={() => {document.getElementById('membershipName').focus(); }}>
                                        <div className="medium font__size--14 text__14-1024 btn membership btn__blue shadow color__white ls-1">문의하기</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4 mb-md-0 membership-table">
                                <div className="head-box">
                                    <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 15.5h5.25L9 14H4.5v-1.352a2.128 2.128 0 0 1 2.125-2.125h4.174l.736-1.473c.186-.492.26-1.043.26-1.618 0-1.063-.254-2.047-.995-2.72C10.08 4.06 9.215 4 8.75 4c-.465 0-1.33.059-2.05.713-.74.672-.995 1.656-.995 2.719 0 .587.077 1.15.271 1.65A3.628 3.628 0 0 0 3 12.646V15.5Zm7.296-8.068c0 .838-.198 1.319-.485 1.59-.299.284-.694.342-1.061.342s-.762-.058-1.061-.341c-.286-.272-.484-.753-.484-1.591 0-1.713.826-1.932 1.545-1.932.72 0 1.546.22 1.546 1.932ZM21.01 20H9.532v-2.85a3.622 3.622 0 0 1 2.967-3.559c-.193-.497-.27-1.058-.27-1.642 0-1.061.254-2.044.994-2.715.72-.654 1.583-.712 2.048-.712.464 0 1.327.058 2.047.712.74.671.994 1.654.994 2.715 0 .584-.077 1.145-.27 1.642a3.622 3.622 0 0 1 2.968 3.559V20Zm-7.859-4.968a2.122 2.122 0 0 0-2.12 2.12V18.5h8.479v-1.349a2.121 2.121 0 0 0-2.12-2.12h-4.239Zm3.182-1.5c.284-.272.48-.751.48-1.583 0-1.708-.825-1.927-1.542-1.927-.718 0-1.542.219-1.542 1.927 0 .832.196 1.31.48 1.583.298.286.694.344 1.062.344.368 0 .764-.058 1.062-.344Z" fill="currentColor"></path>
                                    </svg>
                                    <label className="n__mb__0">Group</label>
                                </div>
                                <div className="body-box">
                                    <div className="membership-title">단체 요금제</div>
                                    <div className="membership-desc">전화/홈페이지 문의</div>
                                    <div className="membership-desc-detail">(단체 상황에 따라 다양한 혜택 제공)</div>
                                    <div className="membership-benefits">
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">2개월 내 사용</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">멤버십 단체 회원 전용 이벤트</label>
                                        </div>
                                        <div className="membership-benefit-item">
                                            <svg viewBox="0 0 24 24" fill="none" className="dig-UIIcon dig-UIIcon--standard" width="24" height="24" focusable="false">
                                                <path d="M5.5 11.5 10 16l8.25-8.25" stroke="#3B71FE" strokeWidth="1.5" strokeMiterlimit="10"></path>
                                            </svg>
                                            <label className="item-label n__mb__0">단체당 최대 10인까지 멤버십 공유 가능</label>
                                        </div>
                                    </div>
                                    <div className="membership-btn" onClick={() => {document.getElementById('membershipName').focus(); }}>
                                        <div className="medium font__size--14 text__14-1024 btn membership btn__blue shadow color__white ls-1">문의하기</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="semi-bold font__size--32 ls-1 mb__3">가입 문의</h3>
                                {window.innerWidth <= 768 && <div className="">
                                    <div className="form-group">
                                        <label htmlFor="" className="medium font__size--14 text__14-1024 color__black">이름</label>
                                        <input type="text" className="font__size--14 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="이름을 입력하세요"  id="membershipName" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="medium font__size--14 text__14-1024 color__black">전화번호</label>
                                        <input type="text" className="font__size--14 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="답변을 받으실 전화번호를 '-'없이 숫자만 입력하세요" id="membershipPhone" maxLength="11" />
                                    </div>
                                </div>
                                }
                                {window.innerWidth > 768 && 
                                <div className="d-flex">
                                    <div className="col-md-6 form-group n__pl__0">
                                        <label htmlFor="" className="medium font__size--14 text__14-1024 color__black">이름</label>
                                        <input type="text" className="font__size--14 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="이름을 입력하세요"  id="membershipName" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="" className="medium font__size--14 text__14-1024 color__black">전화번호</label>
                                        <input type="text" className="font__size--14 text__14-1024 color__gray-2 form-control wrapper__input-field ls-1" placeholder="답변을 받으실 전화번호를 '-'없이 숫자만 입력하세요" id="membershipPhone" maxLength="11" />
                                    </div>
                                </div>
                                }
                                <div className="form-group mb-5">
                                    <label htmlFor="" className="medium font__size--14 text__14-1024 color__black">문의내용</label>
                                    <textarea className="font__size--14 text__14-1024 color__gray-2 form-control wrapper__input-field textarea ls-1" placeholder="문의하실 내용을 입력해주세요" name="" id="membershipInquiry" cols="30" rows="7"></textarea>
                                </div>
                                <div className="text-right">
                                    <button className="medium font__size--16 text__16-1024 btn btn__blue shadow rounded__50 color__white ls-1" onClick={() => {
                                        let 
                                          name    = document.getElementById('membershipName').value
                                        , phone   = document.getElementById('membershipPhone').value
                                        , inquiry = document.getElementById('membershipInquiry').value
                                        ; 
                                        
                                        if(name == '') { alert('이름을 입력해주세요');}
                                        else if(phone == '') { alert('연락받으실 전화번호를 입력해주세요');}
                                        if((name != '') && (phone != '') && (inquiry != '')) {
                                          let options = { 
                                              id: '63293e39cfebbc291545e385',
                                              params: {
                                                id: null,
                                                name: name,
                                                phone_number: phone,
                                                book_way: '선택안함',
                                                book_path: '홈페이지',
                                                book_type: '멤버십문의',
                                                book_date: Date.now(),
                                                start_time: '00:00',
                                                requested_term: inquiry,
                                                memo: null,
                                                approval: 0,
                                                elapsed_hours: '0:00',
                                                book_product: null,
                                                using_people: 0,
                                                appointed_pay: 0,
                                                final_price: 0,
                                                register_date: Date.now() 
                                              }
                                            }

                                          axios.post(
                                            'https://primesheet.net/query/call',
                                            JSON.stringify(options),
                                            {
                                            headers: {
                                              "Content-Type": "application/json; charset=utf-8",
                                            },
                                          })
                                          .then(function (response) { 
                                            document.getElementById('membershipName').value = "";
                                            document.getElementById('membershipPhone').value = "";
                                            document.getElementById('membershipInquiry').value = "";
                                            alert("멤버십 문의가 완료되었습니다. 영업시간 기준 몇시간 내로 문의 내용 확인 후 연락드리도록 하겠습니다. 감사합니다:)"); 
                                          })
                                          .catch(function (error) {
                                            console.log(error);
                                          });
                                        }
                                    }}>문의하기</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    );
};

export default Contact;
