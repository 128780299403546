import React from 'react';
import ReactDOM from 'react-dom';
import useEffect from "react";
import App from './container/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
// import $ from "jquery";
import 'popper.js/dist/popper.min.js';
import './assets/css/main.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import reportWebVitals from './reportWebVitals';

document.title = "공간과 사람을 잇는 곳 - 공간잇";

ReactDOM.render(  
  <React.StrictMode>    
    <App />
  </React.StrictMode>,  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
