import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import AccordionFAQ from "../../component/Other/AccordionFAQ";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";

const Faq = (props) => {
    const accordions = [
        {
            title: "외부음식 반입이나 배달이 되나요?",
            desc: "네, 물론 가능합니다:) 다만, 섭취 후 각 룸에 비치된 안내가이드에 따라 정해진 쓰레기 배출 공간에 버려주시기 바랍니다."
        },
        {
            title: "영업시간은 어떻게 되나요?",
            desc: "00:00 ~ 24:00 입니다:)"
        },
        {
            title: "공휴일에도 예약이 되나요?",
            desc: "네, 공휴일에도 이용 가능합니다:)"
        },
        {
            title: "4인/8인/12인룸은 이용요금은 얼마인가요?",
            desc: "1인기준 평일은 시간당 1,500원, 주말은 시간당 2,000원입니다."
        },
        {
            title: "VIP룸(10인)은 이용요금은 얼마인가요?",
            desc: "VIP룸은 4인기준 시간당 평일은 12,000원, 주말은 15,000원입니다.(1인 1시간 인원추가 2,000원)"
        },
        {
            title: "카페룸은 이용요금은 얼마인가요?",
            desc: "카페룸은 6명기준 시간당 평일/주말 상관없이 15,000원입니다.(1인 1시간 인원추가 2,000원)"
        },
        {
            title: "힐링/파티룸은 이용요금은 얼마인가요?",
            desc: "힐링/파티룸은 4명기준 시간당 평일은 15,000원 주말은 20,000원입니다.(1인 1시간 인원추가 3,000원)"
        },
        {
            title: "주류반입이 가능한가요?",
            desc: "VIP룸(10인),카페룸,힐링/파티룸의 경우 가능하나 처음과 같은 상태로 깔끔한 뒷정리를 해주셔야합니다. 청소가 미흡할 경우 청소요금이 청구될 수 있습니다."
        },
        {
            title: "공간잇 주소/위치를 알려주세요",
            desc: "서울특별시 서대문구 이화여대 8길 3 지하1층입니다. 이대역 3번출구에서 이대 정문방향으로 200m 걸어오시면 공간잇을 발견하실 수 있습니다."
        },
        {
            title: "주차공간이 있나요?",
            desc: "아쉽게도 주차공간이 따로 마련되어있지 않습니다. 가장 가까운 유료 주차장으로는 이대 정문 바로 옆 이대이룸주차장이 있습니다."
        },
        {
            title: "환불규정은 어떻게 되나요?",
            desc: "이용 6일전 90%, 이용 5일전 80%, 이용 4일전 70%, 이용 3일전 60%, 이용 2일전 50%, 이용 전일 30%, 이용 당일은 환불 불가입니다."
        }
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <Navbar />

                <section>
                    <div className="container">
                        <div className="wrapper__head-top position-relative">
                            <img src="./../images/Group 82.png" className="images__1 d-none d-xl-block" alt="" />
                            <img src="./../images/Frame 889.png" className="images__2 d-none d-xl-block" alt="" />
                            <div className="text-center position-relative z-2">
                                <h5 className="medium font__size--18 text__18-1024 color__green line__left mb-3 justify-content-center ls-1">FAQs</h5>
                                <h1 className="semi-bdol font__size--48 text__48-1024 text__48-md text__48-sm ls-1">자주 묻는 질문</h1>
                                <p className="normal font__size--16 text__16-1024 color__gray-2 mb-4 ls-1 wb-ws">자주 질문해주시는 내용을 모아 보다 편리하게 궁금증을 알아볼 수 있습니다.  <br className="d-none d-md-block wb-ws" />원하시는 질문이 없으실 경우 네이버톡톡 또는 전화로 문의해주세요:)</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-0">
                    <div className="container">
                        <ul className="nav nav-pills wrapper__nav-travel mb-5" id="pills-tab" role="tablist">
                            <li className="nav-item mb-3 mb-md-0">
                                <a className="nav-link medium font__size--14 text__14-1024 color__gray-2 active" id="pills-Experience-tab" data-toggle="pill" href="#pills-Experience" role="tab" aria-controls="pills-Experience" aria-selected="false">
                                    <div className="d-flex align-items-center svg">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.29167 18.9584C3.95 18.9584 3.66667 18.6751 3.66667 18.3334V1.66675C3.66667 1.32508 3.95 1.04175 4.29167 1.04175C4.63333 1.04175 4.91667 1.32508 4.91667 1.66675V18.3334C4.91667 18.6751 4.63333 18.9584 4.29167 18.9584Z" fill="#7D7C84" />
                                            <path d="M13.625 13.9583H4.29167C3.95 13.9583 3.66667 13.6749 3.66667 13.3333C3.66667 12.9916 3.95 12.7083 4.29167 12.7083H13.625C14.5333 12.7083 14.9583 12.4666 15.0417 12.2583C15.125 12.0499 15 11.5833 14.35 10.9416L13.35 9.94159C12.9417 9.58325 12.6917 9.04159 12.6667 8.44159C12.6417 7.80825 12.8917 7.18325 13.35 6.72492L14.35 5.72492C14.9667 5.10825 15.1583 4.60825 15.0667 4.39159C14.975 4.17492 14.5 3.95825 13.625 3.95825H4.29167C3.94167 3.95825 3.66667 3.67492 3.66667 3.33325C3.66667 2.99159 3.95 2.70825 4.29167 2.70825H13.625C15.45 2.70825 16.0333 3.46659 16.225 3.91659C16.4083 4.36659 16.5333 5.31659 15.2333 6.61659L14.2333 7.61659C14.025 7.82492 13.9083 8.11659 13.9167 8.40825C13.925 8.65825 14.025 8.88325 14.2 9.04159L15.2333 10.0666C16.5083 11.3416 16.3833 12.2916 16.2 12.7499C16.0083 13.1916 15.4167 13.9583 13.625 13.9583Z" fill="#7D7C84" />
                                        </svg>
                                        <span className="ml-2">FAQs</span>
                                    </div>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-General" role="tabpanel" aria-labelledby="pills-General-tab">
                                {
                                    accordions.map((obj, index) => {
                                        return <div key={index} className="mb-2 wb-ws">
                                            <AccordionFAQ data={obj} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="pills-Host" role="tabpanel" aria-labelledby="pills-Host-tab">
                                {
                                    accordions.map((obj, index) => {
                                        return <div key={index} className="mb-2 wb-ws">
                                            <AccordionFAQ data={obj} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="pills-Experience" role="tabpanel" aria-labelledby="pills-Experience-tab">
                                {
                                    accordions.map((obj, index) => {
                                        return <div key={index} className="mb-2 wb-ws">
                                            <AccordionFAQ data={obj} />
                                        </div>
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="pills-Travel" role="tabpanel" aria-labelledby="pills-Travel-tab">
                                {
                                    accordions.map((obj, index) => {
                                        return <div key={index} className="mb-2 wb-ws">
                                            <AccordionFAQ data={obj} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    );
};

export default Faq;
